import { useContext } from 'react'
import summaryContext from 'context/summary'
import styled from 'styled-components'
import { getRGBA } from 'utils/analysis'
import  { useIntl } from 'react-intl'

// 코멘트 스타일
const CommentsStyled = styled.div`
    padding: 20px 20px 0 20px;
    min-height: 80px;
    max-height: 400px;
    overflow: auto;
    transition: all .3s;
    border-radius: 3px;
    background: ${props => props.bg};
    position: relative;

    &._09 {
        background: ${props => props.bg};
    }

    & > div {
        padding: .5em 1em;
    }

    & > div + div {
        margin-top: 1.1em;
    }
`

export default (props) => {
    const { id, children } = props
    const { design } = useContext(summaryContext)

    const { formatMessage: f } = useIntl()

    const bg = getRGBA({color: design.question, opacity: .1})

    return (
        <CommentsStyled id={id} bg={bg}>
            {children}
        </CommentsStyled>
    )
}