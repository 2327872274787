import { useContext, useMemo, memo } from 'react'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import summaryContext from 'context/summary'
import styled, { css } from 'styled-components'
import { getRGBA } from 'utils/analysis'
import Tooltip from 'styled/Custom/Tooltip'

const useStyles = makeStyles(theme => ({
    percent: props => {
        const { color, fontFamily } = props
        return { color, fontFamily, lineHeight: '120%', marginBottom: 3, fontSize: 14 }
    },
    count: props => {
        const { color2, fontFamily } = props
        return { 
            color: color2,
            fontFamily,
            lineHeight: '120%',
            marginBottom: 3,
            fontSize: 13,
            [theme.breakpoints.down('xs')]: {
                display: 'none',
                marginBottom: 0
            }
        }
    }
}))

const WrapperStyled =styled.div`
    & + & {
        margin-top: 12.19em;
    }
`

const ContsStyled = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`

// 게이지 바 스타일
const BarHoriStyled = styled.div`
    width: 100%;
    height: 120px;
    border-radius: 3px;
    position: relative;
    z-index: 1;


    & > div.bg {
        position: absolute;
        z-index: 2;
        background: ${({ bg }) => bg};
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: 3px;
    }

    & > div.bar {
        background: ${({ bar }) => bar};
        z-index: 2;
        transition: all .3s;
        height: 0;
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
    }

    & > div.bar.pink {
        background: ${({ bar }) => bar};
    }

    & > div.bar.animation {
        width: 100%;
        height: ${({per}) => per}%;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
        ${({per}) => Number(per.toFixed(1)) === 100.00 && css`
            border-top-left-radius: 3px;
            border-top-right-radius: 3px;
        `}
    }
`

function Component(props) {
    const { answer, per, cols } = props
    const { viewMode, design } = useContext(summaryContext)

    // 보기내용
    const color1 = getRGBA({color: design.question, opacity: 1})

    // 응답수 색상
    const color2 = getRGBA({color: design.question, opacity: .7})

    
    // 바 배경색
    const bg = getRGBA({color: design.bar, opacity: .3})
    // 바 색
    const bar = getRGBA({color: design.bar, opacity: 1})

    const classes = useStyles({ color: color1, color2: color2, fontFamily: design.font_family })
    
    return (
        <WrapperStyled>
            {
                useMemo(() => {
                    return (
                        <ContsStyled>
                            <Typography variant="body2" display="block" className={classes.percent}>{`${per.toFixed(1)}%`}</Typography>
                            <Typography variant="caption" display="block" className={classes.count}>{cols}</Typography>
                        </ContsStyled>
                    )
                }, [classes.percent, classes.count, per, cols])
            }
            {
                useMemo(() => {
                    return (
                        <Tooltip title={answer}>
                            <BarHoriStyled per={per} viewMode={viewMode} bg={bg} bar={bar}>
                                <div className="bg"></div>
                                <div className="bar animation"></div>
                            </BarHoriStyled>
                        </Tooltip>
                    )
                }, [answer, per, viewMode, bg, bar])
            }
        </WrapperStyled>
    )
}

export default memo(Component)