import gql from 'graphql-tag'
import { QuestionOption } from 'component/Survey/Create/NewAnalysis/Filter/Component'

const schema = `
    common {
        question_type
        module_type
        survey_question_no
        question
        number
        img_src
        summary_question_open
        summary_comment_open
        kind
    }

    ... on Summary_01 {
        modules {
            survey_module_no
            answer
            i
            count
            comments {
                idd
                comment
                wdate
            }
            commentLen
        }
        isComments
    }

    ... on Summary_02 {
        modules {
            survey_module_no
            answer
            i
            count
            comments {
                idd
                comment
                wdate
            }
            commentLen
        }
        isComments
    }

    ... on Summary_03 {
        modules {
            survey_module_no
            column
            answers {
                survey_module_answer_no
                answer
                i
                count
            }
        }

        answers {
            survey_module_answer_no
            answer
            i
            count
        }

        comments {
            idd
            comment
            wdate
        }
        commentLen
        isComments
    }

    ... on Summary_04 {
        modules {
            survey_module_no
            column
            answers {
                survey_module_answer_no
                answer
                i
                count
            }
        }
        answers {
            survey_module_answer_no
            answer
            i
            count
        }
        comments {
            idd
            comment
            wdate
        }
        commentLen
        isComments
    }

    ... on Summary_05 {
        modules {
            survey_module_no
            answer
            indexs
            i
            count
            point
        }
        comments {
            idd
            comment
            wdate
        }
        commentLen
        option {
            left_label
            right_label
            reverse
            zero_number
            nps
        }
        isComments
    }

    ... on Summary_06 {
        modules {
            survey_module_no
            column
            answers {
                survey_module_answer_no
                answer
                indexs
                i
                count
                point
            }
        }
        answers {
            survey_module_answer_no
            answer
            indexs
            i
            count
            point
        }
        comments {
            idd
            comment
            wdate
        }
        commentLen
        option {
            left_label
            right_label
            reverse
            zero_number
            nps
        }
        isComments
    }

    ... on Summary_07 {
        modules {
            survey_module_no
            answer
            indexs
            i
            count
            point
        }
        comments {
            idd
            comment
            wdate
        }
        commentLen
        isComments
    }

    ... on Summary_08 {
        modules {
            survey_module_no
            column
            answers {
                survey_module_answer_no
                answer
                indexs
                i
                count
                point
            }
        }
        answers {
            survey_module_answer_no
            answer
            indexs
            i
            count
            point
        }
        comments {
            idd
            comment
            wdate
        }
        commentLen
        isComments
    }

    ... on Summary_09 {
        comments {
            idd
            comment
            wdate
        }
        commentLen
    }

    ... on Summary_10 {
        comments {
            idd
            comment
            wdate
        }
        commentLen
    }

    ... on Summary_11 {
        modules {
            survey_module_answer_no
            answer
            indexs
            i
            deviation
            point
        }
        answers {
            survey_module_answer_no
            answer
            i
            ranks {
                survey_module_no
                indexs
                i
                count
            }
        }
        comments {
            idd
            comment
            wdate
        }
        commentLen
        isComments
    }

    ... on Summary_12 {
        modules {
            survey_module_no
            answer
            src
            i
            count
            comments {
                idd
                comment
                wdate
            }
            commentLen
        }
        isComments
    }

    ... on Summary_13 {
        modules {
            survey_module_no
            answer
            src
            i
            count
            comments {
                idd
                comment
                wdate
            }
            commentLen
        }
        isComments
    }

    ... on Summary_14 {
        modules {
            survey_module_no
            answer
            indexs
            i
            count
            point
        }
        comments {
            idd
            comment
            wdate
        }
        commentLen
        isComments
    }

    ... on Summary_15 {
        modules {
            survey_module_no
            column
            answers {
                survey_module_answer_no
                answer
                indexs
                i
                count
                point
            }
        }
        answers {
            survey_module_answer_no
            answer
            indexs
            i
            count
            point
        }
        comments {
            idd
            comment
            wdate
        }
        commentLen
        isComments
    }

    ... on Summary_17 {
        modules {
            survey_module_answer_no
            answer
            indexs
            i
            deviation
            point
        }
        answers {
            survey_module_answer_no
            answer
            i
            ranks {
                survey_module_no
                indexs
                i
                count
                comments {
                    idd
                    comment
                    wdate
                }
                commentLen
            }
        }
        option {
            required
            required_end
        }
        isComments
    }

    ... on Summary_18 {
        modules {
            survey_module_answer_no
            answer
            indexs
            src
            i
            deviation
            point
        }
        answers {
            survey_module_answer_no
            answer
            src
            i
            ranks {
                survey_module_no
                indexs
                i
                count
                comments {
                    idd
                    comment
                    wdate
                }
                commentLen
            }
        }
        option {
            required
            required_end
        }
        isComments
    }

    ... on Summary_19 {
        modules {
            survey_analysis_users_no
            src
            filename
            filesize
            wdate
        }
        moduleLen
        comments {
            idd
            comment
            wdate
        }
        commentLen
        isComments
    }

    ... on Summary_20 {
        comments {
            idd
            comment
            wdate
        }
        commentLen
    }

    ... on Summary_21 {
        modules {
            survey_module_no
            answer
            i
            count
        }
        comments {
            idd
            comment
            wdate
        }
        commentLen
        isComments
    }

    ... on Summary_ending {
        modules {
            survey_ending_no
            pure_message
            img_src
            count
        }
    }
`
export const GET_SURVEY_ANALYSIS_SUMMARY = gql`
    query SurveyAnalysisSummary($survey_no: Int!, $mode: Mode!, $input: SurveyAnalysisSummaryInput!, $key: String!) {
        surveyAnalysisSummary(survey_no: $survey_no, mode: $mode, input: $input, key: $key) {
            response
            instance {
                ${schema}
            }
        }
    }
`

export const GET_SURVEY_ANALYSIS_SUMMARY_COMMENTS = gql`
    query SurveyAnalysisSummaryComments($survey_no: Int!, $key: String!, $id: String!, $offset: Int!) {
        surveyAnalysisSummaryComments(survey_no: $survey_no, key: $key, id: $id, offset: $offset) {
            idd
            comment
            wdate
        }
    }
`

export const GET_SURVEY_ANALYSIS_SUMMARY_FILES = gql`
    query SurveyAnalysisSummaryFiles($survey_no: Int!, $key: String!, $id: String!, $offset: Int!) {
        surveyAnalysisSummaryFiles(survey_no: $survey_no, key: $key, id: $id, offset: $offset) {
            survey_analysis_users_no
            filename
            filesize
            src
            wdate
        }
    }
`

export const GET_SURVEY_ANALYSIS_SUMMARY_COMMENT_QUESTION = gql`
    query SurveyAnalysisSummaryCommentQuestion($survey_no: Int!, $key: String!, $survey_question_no: Int!) {
        surveyAnalysisSummaryCommentQuestion(survey_no: $survey_no, key: $key, survey_question_no: $survey_question_no) {
            idd
            comment
            wdate
        }
    }
`

export const GET_SURVEY_ANALYSIS_SUMMARY_FILE_QUESTION = gql`
    query SurveyAnalysisSummaryFileQuestion($survey_no: Int!, $key: String!, $survey_question_no: Int!) {
        surveyAnalysisSummaryFileQuestion(survey_no: $survey_no, key: $key, survey_question_no: $survey_question_no) {
            survey_analysis_users_no
            src
            filename
            filesize
            wdate
        }
    }
`

export const GET_SURVEY_ANALYSIS_SUMMARY_COMMENT_QUESTION_MODULE = gql`
    query SurveyAnalysisSummaryCommentQuestionModule($survey_no: Int!, $key: String!, $survey_question_no: Int!) {
        surveyAnalysisSummaryCommentQuestionModule(survey_no: $survey_no, key: $key, survey_question_no: $survey_question_no)
    }
`


export const GET_SURVEY_ANALYSIS_NEW_SUMMARY = gql`
query SurveyAnalysisNewSummary($survey_no: Int!, $mode: Mode!, $input: SurveyAnalysisNewSummaryInput!) {
    surveyAnalysisNewSummary(survey_no: $survey_no, mode: $mode, input: $input) {
        response
        instance {
            ${schema}
        }
    }
}
`

// 진행상황: 모두 완료 진행중
export enum Step {
    All,
    End,
    Ing,
}
/*
** edit:   레포트 편집
** view:   요약 페이지
** report: 공개 페이지
*/
export enum ViewMode {
    Edit="edit",
    View="view",
    Report="report"
}

export type Print = boolean;

export enum Mode {
    Edit="edit",
    Live="live",
    Preview="preview"
}

export enum QuestionType {
    Question="question",
    Explain="explain",
    Ending="ending"
}

export interface SurveyAnalysisSummaryVariables {
    survey_no: number;
    mode: Mode;
    input: {
        conditionsPlay: QuestionOption | null;
        step: Step;
        date: string | null;
        search: string | null;
        viewMode: ViewMode;
        target_survey_analysis_users_no: number | null;
    }
    key: string;
}

export interface SurveyAnalysisSummaryNewVariables {
    survey_no: number;
    mode: Mode;
    input: {
        filter: {};
        step: Step;
        date: string | null;
        search: string | null;
        viewMode: ViewMode;
        target_survey_analysis_users_no: number | null;
    }
}

type Common = {
    question_type: QuestionType;
    module_type: '_01' | '_02' | '_03' | '_04' | '_05' | '_06' | '_07' | '_08' | '_09' | '_10' | '_11' | '_12' | '_13' | '_14' | '_15' | '_16' | '_17' | '_18' | '_19' | '_ending';
    survey_question_no: number;
    question: string;
    number: number;
    img_src: string | null;
    summary_question_open: boolean;
    summary_comment_open: boolean;
    kind: string;
}

type _01 = {
    common: Common;
    modules: {
        survey_module_no: number;
        answer: string;
        i: number;
        count: number;
        comments: {
            idd: number;
            comment: string;
            wdate: string;
        }
    }[];
    isComments: boolean;
}

type _02 = {
    common: Common;
    modules: {
        survey_module_no: number;
        answer: string;
        i: number;
        count: number;
        comments: {
            idd: number;
            comment: string;
            wdate: string;
        }
    }[];
    isComments: boolean;
}

type _03 = {
    common: Common;
    modules: {
        survey_module_no: number;
        column: string;
        answers: {
            survey_module_answer_no: number;
            answer: string;
            i: number;
            count: number;
        }
    }[];
    answers: {
        survey_module_answer_no: number;
        answer: string;
        i: number;
        count: number;
    }[];
    comments: {
        idd: number;
        comment: string;
        wdate: string;
    }[];
    isComments: boolean;
}

type _04 = {
    common: Common;
    modules: {
        survey_module_no: number;
        column: string;
        answers: {
            survey_module_answer_no: number;
            answer: string;
            i: number;
            count: number;
        }
    }[];
    answers: {
        survey_module_answer_no: number;
        answer: string;
        i: number;
        count: number;
    }[];
    comments: {
        idd: number;
        comment: string;
        wdate: string;
    }[];
    isComments: boolean;
}

export type _05 = {
    common: Common;
    modules: {
        survey_module_no: number;
        answer: string;
        indexs: number;
        i: number;
        count: number;
        point: number;
    }[];
    comments: {
        idd: number;
        comment: string;
        wdate: string;
    }[];
    option: {
        left_label: string;
        right_label: string;
    };
    isComments: boolean;
}

export type _06 = {
    common: Common;
    modules: {
        survey_module_no: number;
        column: string;
        answers: {
            survey_module_answer_no: number;
            answer: string;
            i: number;
            count: number;
            point: number;
        }
    }[];
    answers: {
        survey_module_answer_no: number;
        answer: string;
        i: number;
        count: number;
        point: number;
    }[];
    comments: {
        idd: number;
        comment: string;
        wdate: string;
    }[];
    option: {
        left_label: string;
        right_label: string;
    };
    isComments: boolean;
}

type _07 = {
    common: Common;
    modules: {
        survey_module_no: number;
        answer: string;
        indexs: number;
        i: number;
        count: number;
        point: number;
    }[];
    comments: {
        idd: number;
        comment: string;
        wdate: string;
    }[];
    isComments: boolean;
}

type _08 = {
    common: Common;
    modules: {
        survey_module_no: number;
        column: string;
        answers: {
            survey_module_answer_no: number;
            answer: string;
            i: number;
            count: number;
            point: number;
        }
    }[];
    answers: {
        survey_module_answer_no: number;
        answer: string;
        i: number;
        count: number;
        point: number;
    }[];
    comments: {
        idd: number;
        comment: string;
        wdate: string;
    }[];
    isComments: boolean;
}

type _09 = {
    common: Common;
    comments: {
        idd: number;
        comment: string;
        wdate: string;
    }[];
}

type _10 = {
    common: Common;
    comments: {
        idd: number;
        comment: string;
        wdate: string;
    }[];
}

type _11 = {
    common: Common;
    modules: {
        survey_module_answer_no: number;
        answer: string;
        indexs: number;
        i: number;
        deviation: number;
        point: number;
    }[];
    answers: {
        survey_module_answer_no: number;
        answer: string  | null;
        i: number;
        ranks: {
            survey_module_no: number;
            indexs: number;
            i: number;
            count: number;
        }
    }[];
    comments: {
        idd: number;
        comment: string;
        wdate: string;
    }[];
    isComments: boolean;
}

export type _12 = {
    common: Common;
    modules: {
        survey_module_no: number;
        answer: string;
        src: string | null;
        i: number;
        count: number;
        comments: {
            idd: number;
            comment: string;
            wdate: string;
        }[];
    }[];
    isComments: boolean;
}

type _13 = {
    common: Common;
    modules: {
        survey_module_no: number;
        answer: string;
        src: string | null;
        i: number;
        count: number;
        comments: {
            idd: number;
            comment: string;
            wdate: string;
        }
    }[];
    isComments: boolean;
}

type _14 = {
    common: Common;
    modules: {
        survey_module_no: number;
        answer: string;
        indexs: number;
        i: number;
        count: number;
        point: number;
    }[];
    comments: {
        idd: number;
        comment: string;
        wdate: string;
    }[];
    isComments: boolean;
}

type _15 = {
    common: Common;
    modules: {
        survey_module_no: number;
        column: string;
        answers: {
            survey_module_answer_no: number;
            answer: string;
            i: number;
            count: number;
            point: number;
        }
    };
    answers: {
        survey_module_answer_no: number;
        answer: string;
        i: number;
        count: number;
        point: number;
    }[];
    comments: {
        idd: number;
        comment: string;
        wdate: string;
    }[];
    isComments: boolean;
}

export type _17 = {
    common: Common;
    modules: {
        survey_module_answer_no: number;
        answer: string;
        indexs: number;
        i: number;
        deviation: number;
        point: number;
    }[];
    answers: {
        survey_module_answer_no: number;
        answer: string;
        i: number;
        ranks: {
            survey_module_no: number;
            indexs: number;
            i: number;
            count: number;
            comments: {
                idd: number;
                comment: string;
                wdate: string;
            }[];
            commentLen: number;
        }
    }[];
    option: {
        required: number;
        required_end: number;
    }
    isComments: boolean;
}

export type _18 = {
    common: Common;
    modules: {
        survey_module_answer_no: number;
        answer: string;
        indexs: number;
        src: string | null;
        i: number;
        deviation: number;
        point: number;
    }[];
    answers: {
        survey_module_answer_no: number;
        answer: string;
        src: string | null;
        i: number;
        ranks: {
            survey_module_no: number;
            indexs: number;
            i: number;
            count: number;
            comments: {
                idd: number;
                comment: string;
                wdate: string;
            }[];
            commentLen: number;
        }[];
    }[];
    option: {
        required: number;
        required_end: number;
    }
    isComments: boolean;
}


export type _19 = {
    common: Common;
    modules: {
        survey_analysis_users_no: number;
        src: string;
        filename: string;
        filesize: number;
        wdate: string;
    }[];
    moduleLen: number;
    comments: {
        idd: number;
        comment: string;
        wdate: string;
    }[];
    commentLen: number;
    isComments: boolean;
}

export type _Ending = {
    common: Common;
    modules: {
        survey_ending_no: number;
        pure_message: string | null;
        img_src: string | null;
        count: number;
    }[]
}

export type SurveyAnalysisSummaryProps = _01 | _02 | _03 | _04 | _05 | _06 | _07 | _08 | _09 | _10 | _11 | _12 | _13 | _14 | _15 | _17 | _18 | _19 | _Ending;