import axios from 'axios'
import { Kind as FilterKind } from 'component/Survey/Create/Design/Custom/Filter/Component'

interface GetUserSignedUrlProps {
    filename: string;
}

interface GetFilterSignedUrlProps {
    filenames: string[];
    kind: FilterKind;
}

interface GetSignedUrlProps {
    mode: string;
    survey_no: number;
    method: string;
    data: {filename: string, survey_question_no: number}[];
}

interface DeleteUserFile {
    srcs: string[];
}

interface DeleteFile {
    mode: string;
    survey_no: number;
    srcs: string[];
}




// 이미지 리사이즈
const getImageResize = (file: File) => {
    return new Promise((resolve, reject) => {
        var reader = new FileReader();
    
        reader.onload = function(event) {
            const img = new Image();
            img.src = event.target.result;
    
            img.onload = async function() {
                const canvas = document.createElement('canvas')

                let width = img.width;
                let height = img.height;

                if (width > height) {
                    const maxWidth = 1280
                    if (width > maxWidth) {
                        height *= maxWidth / width;
                        width = maxWidth;
                    }
                } else {
                    const maxHeight = 1280
                    if (height > maxHeight) {
                        width *= maxHeight / height;
                        height = maxHeight;
                    }
                }

                canvas.width = width
                canvas.height = height

                const ctx: CanvasRenderingContext2D | null = canvas.getContext('2d')

                if (!ctx) {
                    resolve('')
                    return
                }

                ctx.clearRect(0, 0, canvas.width, canvas.height)

                //ctx.fillStyle = filterProperty.backgroundColor  
                ctx.drawImage(img, 0, 0, canvas.width, canvas.height)

                const dataUrl = canvas.toDataURL(file.type)

                canvas.remove()
                
                const res = await fetch(dataUrl)
                const blob = await res.blob()

                if (!blob) {
                    resolve('')
                    return
                }

                const newFile =  new File([blob], file.name, {type: blob.type})

                resolve(newFile)
            };
        };
    
        reader.readAsDataURL(file);
    })
}


export const getUserSignedUrl = async(props: GetUserSignedUrlProps) => {
    const { filename } = props

    let signedurl = ''
   
    try {
        const res = await axios({
            url: `${process.env.REACT_APP_API}/awsapi/s3/signedurl/put/user`,
            method: 'post',
            headers: { authorization: localStorage.getItem('authorization') },
            data:  { filename }
        })

        signedurl = res.data
    } catch(e) {
        throw e
    }

    return signedurl
}

// 필터링 이미지
export const getFilterSignedUrl = async(props: GetFilterSignedUrlProps) => {
    const { filenames, kind } = props

    let signedurl = ''
   
    try {
        const res = await axios({
            url: `${process.env.REACT_APP_API}/awsapi/s3/signedurl/put/filter`,
            method: 'post',
            headers: { authorization: localStorage.getItem('authorization') },
            data:  { filenames, kind }
        })

        signedurl = res.data
    } catch(e) {
        throw e
    }

    return signedurl
}


export const getSignedUrl = async(props: GetSignedUrlProps) => {
    const { method, mode, survey_no, data } = props

    let signedurl = []
    const len = data.length
    if (len > 0) {
        const datas = data.map(c => ({
            filename: c.filename,
            survey_question_no: c.survey_question_no
        }))

        try {
            const res = await axios({
                url: `${process.env.REACT_APP_API}/awsapi/s3/signedurl/${method}/${mode}/${survey_no}`,
                method: 'post',
                data: { datas }
            })

            signedurl = res.data
        } catch(e) {
            throw e
        }
    }

    return signedurl
}


export const uploadFile = async(singendUrl:string, file:File) => {
    try {

        const isResizeFile = ['image/jpg', 'image/jpeg']

        // 파일사이즈가 500kbyte 이상이고 png, jpg일경우 리사이즈 한다.
        const newFile: File = (file.size >= 512000 && isResizeFile.includes(file.type)) ? await getImageResize(file) : file

        if (!newFile) {
            window.alert('Nothig File')
            return {
                status: false,
                location: '',
                filesize: 0
            }
        }

        const res = await fetch(
            new Request(singendUrl, {
                method: 'PUT',
                body: newFile,
                headers: new Headers({
                    "Content-type": newFile.type
                })
            })
        )

        const { url } = res
        
        const validSignedUrl = new URL(singendUrl || '')
        const validResUrl = new URL(url || '')
        
        // 값이 없다면 이상한거
        if (!validSignedUrl || !validResUrl) {
            return {
                status: false,
                location: '',
                filesize: 0
            }
        }

        const { origin: originSignedUrl } = validSignedUrl
        const { origin: originRes } = validResUrl
        
        // 도메인이 다르면 실패다
        if (originSignedUrl !== originRes) {
            return {
                status: false,
                location: '',
                filesize: 0
            }
        }
         
        const [ location ] = url.split('?')

        return {
            status: true,
            location: location.replace(process.env.REACT_APP_S3, process.env.REACT_APP_CF),
            filesize: newFile.size
        }
    } catch(e) {
        await axios({
            url: `${process.env.REACT_APP_API}/awsapi/s3/log`,
            method: 'post',
            data: { msg: e.message }
        })
        return {
            status: false,
            location: '',
            filesize: 0
        }
    }
   
}

export const deleteUserFile = async(props: DeleteUserFile) => {
    const { srcs } = props

    const newSrcs = srcs.map(src => src.replace(process.env.REACT_APP_CF, process.env.REACT_APP_S3))

    try {
        await axios({
            url: `${process.env.REACT_APP_API}/awsapi/s3/delete/user`,
            method: 'post',
            headers: { authorization: localStorage.getItem('authorization') },
            data: { srcs: newSrcs }
        })

        return true
    } catch(e) {
        throw e
    }
}

export const deleteFile = async(props: DeleteFile) => {
    const { mode, survey_no, srcs } = props

    
    try {
        await axios({
            url: `${process.env.REACT_APP_API}/awsapi/s3/delete/${mode}/${survey_no}`,
            method: 'post',
            data: { srcs: srcs.map(src => src.replace(process.env.REACT_APP_CF, process.env.REACT_APP_S3)) }
        })

        return true
    } catch(e) {
        throw e
    }
}