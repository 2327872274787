import { memo, useContext, useMemo, useCallback } from 'react'
import { useIntl } from 'react-intl'
import { getMimePicture } from 'utils/survey'
import { handleDisabledRightClick } from 'ts-utils'
import { downloadFile } from 'utils'
import { Typography, Divider } from '@material-ui/core'
import GetAppIcon from '@material-ui/icons/GetApp'
import summaryContext from 'context/summary'
import moment from 'moment'
import { getRGBA } from 'utils/analysis'
import withSurveyPopoverImageNew from 'hoc/Survey/PopoverImageNew'
import { useStyles } from './SummaryCommentItem'

function SummaryFileItemComponent(props) {
    const { name, survey_analysis_users_no, filename, src, wdate, onPopoverImageOpen } = props

    const { viewMode, design } = useContext(summaryContext)

    const { formatMessage: f } = useIntl()

    const color = getRGBA({color: design.question, opacity: 1})

    const classes = useStyles({ color, fontFamily: design.font_family })

    const fullSrc = `${process.env.REACT_APP_LISTO_CF}${src}`
  
    const handleClick = useCallback(async() => {

        const blob = await fetch(fullSrc).then(response => {
            if (response.status !== 200) return null
            return response.blob()
        })

        downloadFile({ filename: `${name}-${survey_analysis_users_no}-${filename}`, data: blob })
    }, [fullSrc, name, survey_analysis_users_no, filename])

    const mime = getMimePicture(fullSrc)
    
    return (
        <div >
            {
                useMemo(() => {
                    return (
                        <>
                            <div>
                               
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    {
                                        mime === 'image'? (
                                            <img 
                                                src={fullSrc} 
                                                style={{ maxWidth: 70, maxHeight: 70, borderRadius: 5, marginBottom: 8, cursor: 'pointer' }} 
                                                alt="listovey" 
                                                loading="lazy"
                                                onClick={() => onPopoverImageOpen(fullSrc)}
                                                onContextMenu={handleDisabledRightClick}
                                            />
                                        ) : (
                                            <Typography variant="body2" display="block" className={`${classes.typography} ${classes.comment}`}>
                                                {filename}
                                            </Typography>
                                        )
                                    }
                                    <GetAppIcon style={{ color: `rgba(${design.bar})`, cursor: 'pointer'}} onClick={handleClick}/>
                                </div>
                            </div>
                            {
                                viewMode === 'view' && (
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <Typography variant="body2" style={{fontSize: 12}} display="block" color="textSecondary" className={classes.typography}>
                                            {f({id: 'styled.Survey.Analysis.SummaryFileItem.id'}, { id: survey_analysis_users_no })}
                                        </Typography>
                                        <Typography variant="body2" style={{fontSize: 12}} display="block" color="textSecondary" className={`${classes.typography} ${classes.wdate}`}>
                                            {Number.isNaN(Number(wdate)) ? moment(wdate).format('YYYY.MM.DD HH:mm:ss') : moment(Number(wdate)).format('YYYY.MM.DD HH:mm:ss')}
                                        </Typography>
                                    </div>
                                  
                                )
                            }
                            <Divider light className={classes.divider}/>
                      </>  
                    )
                  
                }, [classes.typography, classes.comment, classes.divider, classes.wdate, viewMode, wdate, filename, fullSrc, design.bar, mime, survey_analysis_users_no, onPopoverImageOpen, handleClick])
            }
            
        </div>
    )
}

export default withSurveyPopoverImageNew(memo(SummaryFileItemComponent))