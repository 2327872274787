// 문항수 에러 메시지 파싱
import oc from 'open-color'
import { parseGraphqlError, validation } from 'utils'
import { parsePhoneNumberFromString } from 'libphonenumber-js/max'
import { SurveyEndingProps } from 'gql/survey_ending'
import { _05Option } from 'gql/survey_question_modules'

// 에러 메시지
export function getLimitMessage(e: any) {
    const msg = parseGraphqlError(e)
    if (!/^limit-count/.test(msg)) return msg

    const [ _, value ] = msg.split(':')

    return value.split(',')
}

export const getEndingMssage = (kind:SurveyEndingProps['kind'], message:SurveyEndingProps['message'], redirect_url:SurveyEndingProps['redirect_url']) => {
    switch(kind) {
        case 'content':
            return message || ''
        case 'redirect':
            return redirect_url || ''
        default:
            return ''
    }
}

// nps인지 판단하기
type IsNpsProps = {
    len: number;
    nps: _05Option['nps'];
    reverse: _05Option['reverse'];
    zero_number: _05Option['zero_number'];
}
export const isNps = (props: IsNpsProps) => {
    const { len, nps, reverse, zero_number } = props

    return (len === 11 && nps && !reverse && zero_number) ? true : false
}

/*
** 배포 링크 도메인은 respond.listovey.com, listovey.com 두개로 운영되며 설문번호로 나눈다.
*/
export const getShareDomain = (survey_no: number) => {
    // 기준 번호 보다 같거나 크다면 listovey.com 아니면 respond.listovey.com 
    const initSurveyNo = process.env.REACT_APP_ENV === 'production' ? 10360 : 7753

    return survey_no >= initSurveyNo ? process.env.REACT_APP_NEW_RESPOND : process.env.REACT_APP_RESPOND
}

export const getBackgroundFilter = (props: { background_filter: string }): { filter: string } => {
    const { background_filter } = props

    return !background_filter ? { filter: 'none' } : 
            background_filter === 'blur' ? { filter: 'blur(5px) brightness(.85) opacity(.7)' } : 
            background_filter === 'brightness' ? { filter: 'brightness(.4)' } : 
            background_filter === 'sepia' ? { filter: 'sepia(.8)' } : 
            background_filter === 'saturate' ? { filter: 'saturate(0)' } : { filter: 'none' }
}


// 이메일 배포 (이메일 형식과 핸드폰번호 형식을 체크)
export const validEmailShare = (val: string) => {
    if (validation.email(val)) return 'email' 
    
    const vaildPhone = parsePhoneNumberFromString(String(val), 'KR')
    if (typeof vaildPhone === 'undefined') return ''    
    if (!vaildPhone.isValid()) return ''
    if (vaildPhone.getType().indexOf('MOBILE') === -1) return ''

    return 'phone'
    
}


// plan 표기 스타일
export const getPlanStyle = () => {
    return { color: oc.blue[7], fontSize: 12 }
}