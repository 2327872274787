import styled from 'styled-components'
import { Typography, Paper, ButtonGroup, Button, Divider } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import oc from 'open-color'

// 코멘트 Box
const CommentPaper = withStyles({
    root: {
        position: 'relative',
        border: `1px solid ${oc.gray[4]}`
    }
})(props => <Paper {...props} variant="outlined"/>)

// 상단 버튼 박스
const IconsBoxStyled = styled.div`
    
`

// Question Number Box
const QuestionNumberStyled = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    padding: .7em 0 .7em 1em;

    & > div:first-child {
        font-weight: bold;
    }

    border-bottom: 1px solid ${oc.gray[1]};
`

// 내용 박스
const QuestionBoxStyled = styled.div`
    padding-top: 1.5em;
    padding-left: 40px;
`

// 이미지를 만들기위한 박스
const QuestionBoxForMakeImageStyled = styled.div`
    // 더보기 내용이 안열렸을때
    &.make-image-more-none {
        & .more {
            display: none;
        }
    }

    // 더보기 내용이 열렸을때
    &.make-image-more-opacity {
        & .more {
            opacity: 0;
        }
    }

    // 더보기 내용이  열렸을때 스크롤 생기면 안되니까..
    &.make-image-collapse {
        & .collapse {
            max-height: 10000000px;
        }
    }
`

// 게이지 바 랩퍼 
const WrapperBarStyled = styled.div`
    & + & {
        margin-top: .7em;
    }
`


const WrapperBarRankStyled = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: repeat( auto-fit, minmax(38px, 1fr) );
    gap: 15px 8px;
`

const ImgStyled = styled.div`
    width: 100%;
    height: 100%;
    background: ${({src}) => `url(${src}) no-repeat`};
    background-size: 100% 100%;
    border-radius: 3px;
    cursor: pointer;
`


// 버튼그룹
const MyButtonGroup = withStyles({
    root: (props) => {
        const { style } = props

        return {
            ...style
        }
    }
})(props => <ButtonGroup {...props} disableElevation variant="outlined" size="small"/>)

// 버튼
const MyButton = withStyles({
    root: {
    
    }
})(props => {
    const { onClick, children, ...other } = props

    return (
        <Button {...other} size="small" onClick={onClick}>
            <Typography variant="caption">{children}</Typography>
        </Button>
    )
})

const MyDivider = withStyles({
    root: {
        background: oc.gray[4]
    }
})(Divider)

export {
    CommentPaper,
    IconsBoxStyled,
    QuestionBoxStyled,
    QuestionBoxForMakeImageStyled,
    QuestionNumberStyled,
    WrapperBarStyled,
    WrapperBarRankStyled,
    ImgStyled,
    MyButtonGroup,
    MyButton,
    MyDivider
}